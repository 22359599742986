document.addEventListener('turbolinks:load', function (event) {
  flatpickr(".js-datepicker", {
  	dateFormat: "Y/m/d",
  	altFormat: 'd-m-Y',
  	altInput: true
  }); 

  flatpickr(".js-datetimepicker", {
    dateFormat: "Y/m/d H:i",
    altFormat: 'd-m-Y H:i',
    altInput: true,
    enableTime: true,
    defaultHour: 23,
    defaultMinute: 59,
    time_24hr: true
  });

  previewUploadedImages();
  bindAlerts();

  bindNoteFormSubmit();
  // bindCandidateCountrySelect();
});


$.fn.previewImage = function(options) {
  var settings = $.extend({
    target: ''
  }, options );

  this.change(function(){
    return readURL(this, settings.target);
  })

  readURL = function(input, container) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function(e) {
        return container.attr('src', e.target.result);
      };
      return reader.readAsDataURL(input.files[0]);
    }
  };
}


function previewUploadedImages(){
  $('.js-image-preview-source').previewImage({ target: $('.js-image-preview-target') });

  // $('.js-image-preview-source2').previewImage({ target: $('.js-image-preview-target2') });

  // $('.js-image-preview-source3').previewImage({ target: $('.js-image-preview-target3') });


  $('.js-image-preview-target').click(function(){
    $('.js-image-preview-source').click();
  });

  // $('.js-image-preview-target2').click(function(){
  //   $('.js-image-preview-source2').click();
  // });


  // $('.js-image-preview-target3').click(function(){
  //   $('.js-image-preview-source3').click();
  // });
}



// alerts

var bindAlerts = function(){
  $('#alerts-container').on('click', '.alert', function(){
    var clickedAlert = $(this);

    setTimeout(function(){ 
      clickedAlert.remove();
    }, 300);
  })

  setTimeout(function(){ 
    $('.alert').fadeOut(1000, function() {
      $(this).remove();
    });
  }, 6000);

  // $('.alert').fadeOut(7000, function() {
  //   $(this).remove();
  // });
}

var bindNoteFormSubmit = function() {
  $('.note-form').on('ajax:success', function(event) {
    $(this).find('input[name=text]').val('');
    [data, status, xhr] = event.detail;
    alert('Note saved!');
  }).on('ajax:error', function(event){
    alert('Note not saved!');
  });

  // $(".note-form").on("ajax:success", (event) ->
  //   [data, status, xhr] = event.detail
  //   $("#new_article").append xhr.responseText
  // ).on "ajax:error", (event) ->
  //   $("#new_article").append "<p>ERROR</p>"

}
// var bindCandidateCountrySelect = function() {
//   alert('111');
//   $('.js-candidate-country').change(function(){
//     let countryId = $(this).val();
//     console.log(countryId);
//   })
// }
