<template>
    <div class="search-list--parent">
        <i class="flaticon2-back-1 filters--expander" title="Show/hide filters" v-on:click="toggleFilters()"></i>
        <h3 class="search-list--header">Results ({{ Pagination.total_count }}) <a v-if="jobRequest.name" v-bind:href="`/job_requests/${jobRequest.id}/dossier`">{{ jobRequest.name }}</a></h3>
        <div class="search-list__row search-list__row--1 search-list--titles">
            <div class="search-list--title">Reg.</div>
            <div class="search-list--title">Name</div>
            <div class="search-list--title">Updated</div>
            <div class="search-list--title">Dist.</div>
            <div class="search-list--title">Address</div>
            <!--
            <div class="search-list--title">Phone</div>
            -->
            <div class="search-list--title">Salary</div>
            <div class="search-list--title">Matches</div>
            <div class="search-list--title"></div>
            <div class="search-list--title">CV</div>
            <div class="search-list--title"></div>
            <div class="search-list--title"></div>
        </div>
        <div class="search-list--scroll-wrap" v-bind:class="{ 'search-list--loading': pageIsLoading }">
            <!-- <div v-if="pageIsLoading" class="search-list--wrapper"></div> -->
            <div class="search-list--wrapper">
                <div class="search-list__row search-list__row--1 search-list--titles">
                    <div class="search-list--title">Reg.</div>
                    <div class="search-list--title">Name</div>
                    <div class="search-list--title">Updated</div>
                    <div class="search-list--title">Dist.</div>
                    <div class="search-list--title">Address</div>
                    <!--
                    <div class="search-list--title">Phone</div>
                    -->
                    <div class="search-list--title">Salary</div>
                    <div class="search-list--title">Matches</div>
                    <div class="search-list--title"></div>
                    <div class="search-list--title">CV</div>
                    <div class="search-list--title"></div>
                    <div class="search-list--title"></div>
                </div>
                <div
                    class="search-list--item"
                    v-for="candidate in Candidates"
                    v-bind:key="candidate.vue_key">
                        <div class="search-list__row search-list__row--1" v-if="!pageIsLoading">
                            <div title="Candidate registration number">{{ candidate.registration_nr }}</div>
                            <div v-bind:title="`${candidate.name} (links to info page)`" class="search-list__result--candidate">
                                <div class="candidate-flag" v-bind:style="getFlagStyle(candidate.candidate_flag)"></div>
                                <a v-on:mousedown="linkToCandidate($event,candidate)" target="_blank">{{ candidate.name }}</a>
                            </div>
                            <div title="Last updated">{{ candidate.last_updated }}</div>
                            <div title="Distance">{{ candidate.distance }}</div>
                            <div title="Address">{{ candidate.address }}</div>
                            <!--
                            <div title="Mobile phone">{{ candidate.phone_mobile }}</div>
                            -->
                            <div title="Salary">{{ candidate.salary }}</div>
                            <div title="Optionals matched">{{ candidate.matches_optional.count }} of {{ candidate.matches_optional.total_count }}</div>
                            <div title="Total matched">{{ candidate.matches.count }} of {{ candidate.matches.total_count }}</div>
                            <div title="View latest BCV" data-label='B CV'>
                                <a 
                                    v-if="candidate.latest_bcv != null"
                                    v-bind:href="`${candidate.latest_bcv.download_url}`"
                                    target="_blank"
                                    title="View">
                                        <i class="flaticon2-file-1 download-icon"></i>
                                        <div>{{ candidate.latest_bcv.uploaded }}</div>
                                </a>
                            </div>
                            <div>
                                <attach-application-button
                                    v-bind:candidateid="candidate.id"
                                    v-bind:candidatename="candidate.name"
                                ></attach-application-button>
                            </div>
                            <div 
                                v-if="candidate.job_requests.length"
                                class="search-list__jr" 
                                v-on:mouseenter="handleDossierList"
                                v-on:mouseleave="handleDossierList">
                                    <p class="dossier-list" v-bind:class="{ 'jr-highlight': checkJobRequestMatch(candidate.job_requests) }" title="Job request dossiers that candidate is attached to">
                                        {{ getJobRequestCount(candidate) }}
                                    </p>
                                    <div class="search-list__jr-list">
                                        <div
                                            v-for="job_request in candidate.job_requests"
                                            v-bind:key="job_request.name"
                                            v-bind:class="{ 'match-job_request': job_request.matched }">
                                                <a target="_blank" v-bind:href="`job_requests/${job_request.id}/dossier`">
                                                    {{ job_request.name }}
                                                </a>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div class="search-list__row search-list__row--2" v-if="!pageIsLoading">
                            <div 
                                class="search-list--det"
                                v-for="job_title in candidate.job_titles"
                                v-bind:key="job_title.name"
                                v-bind:title="job_title.name"
                                v-bind:class="{
                                    'match-title': job_title.matchTitle,
                                    'match-code': job_title.matchCode,
                                    'match-group': job_title.matchGroup,
                                    'match-class': job_title.matchClass
                                }">
                                    {{ job_title.name }}
                            </div>
                            <div
                                class="search-list--det"
                                v-for="skill in getArray(candidate.professional_skills)"
                                v-bind:key="skill"
                                v-bind:title="skill">
                                    {{ skill }}
                            </div>    
                        </div>

                        <div class="search-list__row search-list__row--3" v-if="!pageIsLoading">
                            <div
                                class="search-list--det"
                                v-for="language in candidate.languages"
                                v-bind:key="language.info"
                                v-bind:title="language.info"
                                v-bind:class="{ 'match-language': language.matched }">
                                    {{ language.info }}
                            </div>
                            <div
                                class="search-list--det"
                                v-for="(software, index) in getArray(candidate.software)"
                                v-bind:key="`${software}[${index}]`"
                                v-bind:title="software">
                                    {{ software }}
                            </div>
                        </div>
                </div>
            </div>

            <div v-if="pageIsLoading" class="table-loader">
                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </div>

        <!--
        <div v-if="!pageIsLoading" class="table__pagination--wrapper">
            <nav role="navigation" aria-label="pager" class="pagination">
                <span v-if="currentPage > 2" class="first" v-on:click="setPage(1)">«</span>
                <span v-if="currentPage > 1" class="prev" v-on:click="setPage(currentPage - 1)">‹</span>

                <span v-if="currentPage > 3" class="page gap">...</span>

                <span v-if="(currentPage - 2) > 0" class="page" v-on:click="setPage(currentPage - 2)">{{ currentPage - 2 }}</span>
                <span v-if="(currentPage - 1) > 0" class="page" v-on:click="setPage(currentPage - 1)">{{ currentPage - 1 }}</span>

                <span class="page current">{{ currentPage }}</span>

                <span v-if="currentPage < totalPages" class="page" v-on:click="setPage(currentPage + 1)">{{ currentPage + 1 }}</span>
                <span v-if="(currentPage + 1) < totalPages" class="page" v-on:click="setPage(currentPage + 2)">{{ currentPage + 2 }}</span>

                <span v-if="(currentPage + 2) < totalPages" class="page gap">...</span>

                <span v-if="currentPage < totalPages" class="next" v-on:click="setPage(currentPage + 1)">›</span>
                <span v-if="currentPage < (totalPages - 1)" class="last" v-on:click="setPage(totalPages)">»</span>
            </nav>
            <div class="pagination-options">
                <div class="pagination-options__showing">
                    Showing {{ Pagination.offset + 1 }} - {{ Pagination.offset + Pagination.count }} of {{ Pagination.total_count }}
                </div>
            </div>
        </div>
        -->
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js'
    import AttachApplicationButton from '../../job-application/attach-application-button.vue'
    import FormField from '../../form-field/form-field.vue'

    export default {
        props: [
            'candidates',
            'jobrequestid'
        ],
        data: function(){
            let initialCandidatesData = JSON.parse(this.candidates || '{}');
            return {
                Candidates: [],
                matchedTitles: [],
                Pagination: initialCandidatesData.pagination,
                currentPage: 1,
                lastPage: 0,
                totalPages: Math.ceil(initialCandidatesData.pagination.total_count/initialCandidatesData.pagination.per_page),
                pageIsLoading: false,
                loadingNewResults: false,
                jobRequest: '',
                appliedFilters: {}
            }
        },
        mounted: function(){ 
            this.pageIsLoading = true;
            this.loadingNewResults = true;
            this.setDataFromResponse({
                'applied_filters': this.getFiltersInstance().appliedFilters,
                'results': JSON.parse(this.candidates || '{}').results,
                'pagination': this.Pagination
            }).setJobRequestTitle();

            const thisVue = this;
            const scrollWrap = $(this.$el).find(`.search-list--scroll-wrap`)[0];

            scrollWrap.addEventListener('scroll',function(event){

                const scrollMax = scrollWrap.scrollHeight;
                const scrollNow = scrollWrap.clientHeight + scrollWrap.scrollTop;
                
                if(scrollNow >= scrollMax && !thisVue.loadingNewResults){
                    thisVue.loadingNewResults = true;
                    thisVue.lastPage++;
                    thisVue.currentPage++;
                    thisVue.getNewPage();
                };
            });
        },
        components: { AttachApplicationButton, FormField },
        methods: {
            getFiltersInstance: function(){ return $(`#candidates-advanced-search-filter-form .filters--wrapper`)[0].__vue__ },
            getArray: function(commaString){ if(commaString){ return commaString.split(',') }else{ return '' } },
            getLanguagesArray: function(languageArray){ let resultingArray = []; languageArray.forEach((Language) => { resultingArray.push(Language.info) }); return resultingArray },
            setPage: function(toPage){ 
                if(!this.pageIsLoading){ 
                    this.lastPage = this.currentPage;
                    this.currentPage = toPage; 
                    this.getNewPage(false); 
                };
            },
            getNewPage: function(fromCreation){
                let getURLOptions = '';
                let currentURL = window.location.href;
                let searchURLIndex = currentURL.indexOf('?');
                if(searchURLIndex != -1){
                    if(currentURL.indexOf('page=') == -1){
                        currentURL = `${currentURL}&page=${this.currentPage}`;
                    }else{
                        currentURL = currentURL.replace(`page=${this.lastPage}`,`page=${this.currentPage}`);
                    };
                    getURLOptions = currentURL.slice(searchURLIndex);
                }else{
                    if(currentURL.indexOf('page=') == -1){
                        currentURL = `${currentURL}?page=${this.currentPage}`;
                    }else{
                        currentURL = currentURL.replace(`page=${this.lastPage}`,`page=${this.currentPage}`);
                    };
                    getURLOptions = `?page=${this.currentPage}`;
                };

                // history.replaceState(history.state,'',currentURL);

                // this.pageIsLoading = true;

                Ajax.sendRequest('GET',{},`/search.json${getURLOptions}`,(Response) => { 
                    this.setDataFromResponse(Response);
                    // if(!fromCreation){ this.$nextTick(() => { $(this.$el).find(`.table__pagination--wrapper`)[0].scrollIntoView(false) }) };
                });
            },
            setDataFromResponse: function(ResponseData,overwrite){
                this.setMatches(ResponseData);
                this.appliedFilters = ResponseData.applied_filters;
                this.Candidates = overwrite ? ResponseData.results : (this.Candidates.length > 0 ? this.Candidates.concat(ResponseData.results) : ResponseData.results);
                this.Pagination = ResponseData.pagination;
                this.totalPages = Math.ceil(ResponseData.pagination.total_count/ResponseData.pagination.per_page);
                this.pageIsLoading = false;
                this.loadingNewResults = false;
                this.fitTable();

                console.log(`LOOK HERE`,this.Candidates);

                let uniqueTitleIds = [];
                let jobTitles = [];

                this.Candidates.forEach((Candidate) => {
                    Candidate.job_titles.forEach((Title) => {
                        if(uniqueTitleIds.indexOf(Title.job_title_id) == -1){
                            uniqueTitleIds.push(Title.job_title_id);
                            jobTitles.push(Title)
                        };
                    });
                });

                this.matchedTitles = jobTitles.filter((Title) => { return Title.matchClass || Title.matchGroup || Title.matchCode || Title.matchTitle });


                const filtersVue = document.querySelectorAll('.filters--wrapper-DEL')[0].__vue__;
                const filters = this.appliedFilters;
                console.log(filtersVue.appliedFilters);

                 
                this.matchedTitles.forEach((Title) => {
                    let foundClass = false;
                    filters.job_classes.forEach((job_class) => {
                        if(!foundClass && job_class.id == Title.job_class_id){
                            foundClass = true;
                            Title.class = job_class.name;
                        };
                    });

                    let foundGroup = false;
                    filters.job_groups.forEach((job_group) => {
                        if(!foundGroup && job_group.id == Title.job_group_id){
                            foundGroup = true;
                            Title.group = job_group.name;
                        };
                    });

                    let foundCode = false;
                    filters.job_codes.forEach((job_code) => {
                        if(!foundCode && job_code.id == Title.job_code_id){
                            foundCode = true;
                            Title.code = job_code.name;
                        };
                    });
                });
            
                console.log(`TITLES`,this.matchedTitles);

                document.getElementsByClassName('search-table--wrapper')[0].__vue__.update();

                return this;
            },
            fitTable: function(){
                //below is Pagination + Table header + Sub header + Page header
                // let headerHeights = 37 + 54 + 66 + 70;
                // let itemHeight = 89;
                // let itemCount = this.Candidates.length;
                // let screenHeight = window.innerHeight;

                // if(itemCount*itemHeight < (screenHeight - headerHeights)){
                //     $(this.$el).addClass(`search-list--fixed`);
                // }else{
                //     $(this.$el).removeClass(`search-list--fixed`);
                // };
            },
            setMatches: function(Data){
                let results = Data.results; 

                let jobFilters = Data.applied_filters;

                let jobClasses = jobFilters.job_class_ids.map(item => Number(item));
                let jobClassFlag = Boolean(jobClasses.length);

                let jobGroups = jobFilters.job_group_ids.map(item => Number(item));
                let jobGroupFlag = Boolean(jobGroups.length);

                let jobCodes = jobFilters.job_code_ids.map(item => Number(item));
                let jobCodeFlag = Boolean(jobCodes.length);

                let jobTitles = jobFilters.job_title_ids.map(item => Number(item));
                let jobTitleFlag = Boolean(jobTitles.length);


                let languageFilters = this.getFiltersInstance().appliedFilters;
                let languages = languageFilters.languages;
                let languageIds = languageFilters.languages.map(Language => Number(Language.language.id));
                let languageFlag = Boolean(languageIds.length);

                results.forEach((Candidate) => {
                    let exactMatches = [];
                    let codeMatches = [];
                    let groupMatches = [];
                    let classMatches = [];
                    let noneMatches = [];

                    Candidate.job_titles.forEach((JobTitle) => {
                        if(jobClassFlag && jobClasses.indexOf(JobTitle.job_class_id) != -1){
                            JobTitle.matchClass = true;
                        };
                        if(jobGroupFlag && jobGroups.indexOf(JobTitle.job_group_id) != -1){
                            JobTitle.matchGroup = true;
                        };
                        if(jobCodeFlag && jobCodes.indexOf(JobTitle.job_code_id) != -1){
                            JobTitle.matchCode = true;
                        };
                        if(jobTitleFlag && jobTitles.indexOf(JobTitle.job_title_id) != -1){
                            JobTitle.matchTitle = true;
                        };

                        if(JobTitle.matchTitle){
                            exactMatches.push(JobTitle);
                        }else if(JobTitle.matchCode){
                            codeMatches.push(JobTitle);
                        }else if(JobTitle.matchGroup){
                            groupMatches.push(JobTitle);
                        }else if(JobTitle.matchClass){
                            classMatches.push(JobTitle);
                        }else{
                            noneMatches.push(JobTitle);
                        };
                    });
                    
                    let sortedTitles = [].concat(exactMatches,codeMatches,groupMatches,classMatches,noneMatches);
                    Candidate.job_titles = sortedTitles;


                    let sortedLanguages = [];
                    let noneMatchedLangs = [];

                    Candidate.languages.forEach((Language) => {
                        if(languageFlag && languageIds.indexOf(Language.language_id) != -1){
                            if(Number(languages[languageIds.indexOf(Language.language_id)].proficiency) <= Language.proficiency){
                                Language.matched = true;
                                sortedLanguages.push(Language);
                            };
                        };
                        if(!Language.matched){
                            noneMatchedLangs.push(Language);
                        };
                    });

                    Candidate.languages = sortedLanguages.concat(noneMatchedLangs);
                });
            },
            toggleFilters: function(newState){
                let searchWrapper = $(this.$el).parent(`.search--wrapper`);
                $(searchWrapper).find(`.filters--wrapper`)[0].__vue__.toggleFilters(newState);
            },
            checkJobRequestMatch: function(jobRequests){
                let readyFlag = false;
                jobRequests.forEach((JobRequest) => {
                    if(!readyFlag && JobRequest.id == this.jobrequestid){
                        readyFlag = true;
                        JobRequest.matched = true;
                    };
                });
                return readyFlag;
            },
            handleDossierList: function(event){
                let dossierList = $(event.target).find(`.search-list__jr-list`)[0];
                if(event.type == 'mouseenter'){
                    // adding padding and border
                    let listHeight = $(dossierList).height() + 10 + 10 + 2 + 2;

                    let listTopY = event.target.offsetTop;
                    let listBottomY = listTopY + listHeight;
                    let tableHeight = event.target.offsetParent.offsetHeight;

                    if(listHeight >= tableHeight){
                        $(dossierList).removeClass('show-wide');
                        $(dossierList).addClass('show-wide');
                    }else if(listBottomY >= tableHeight){
                        if(listTopY - listHeight <= 0){
                            $(dossierList).removeClass('show-wide');
                            $(dossierList).addClass('show-wide');
                        }else{
                            $(dossierList).removeClass('show-up');
                            $(dossierList).addClass('show-up');
                        };
                    };
                }else{
                    $(dossierList).removeClass('show-up');
                    $(dossierList).removeClass('show-wide');
                };
            },
            setJobRequestTitle: function(){
                if(this.jobrequestid){
                    Ajax.sendRequest('GET',{},'/autocomplete/job-requests/open',(Response) => {
                        let readyFlag = false;
                        Response.results.forEach((JobRequest) => {
                            if(!readyFlag && JobRequest.id == this.jobrequestid){ 
                                this.jobRequest = JobRequest 
                            };
                        });
                    });
                };
            },
            linkToCandidate: function(event,Candidate){
                console.log(event)
                let jobRequestModal = $(`#ap-modal`)[0].__vue__;
                let jobRequests = jobRequestModal.jobRequestSelected;

                let link = `/candidates/${Candidate.id}/edit`;
                if(jobRequests.length){
                    link = `${link}?`
                    jobRequests.forEach((JobRequest, index) => { 
                        link = `${link}job_request_ids[]=${JobRequest.id}`;
                        if(index != (jobRequests.length - 1)){
                            link = `${link}&`;
                        };
                    });
                };

                const buttonFlag = event.button == 0 || event.button == 1;
                if(buttonFlag && event.target.className.indexOf('candidate-visited') == -1){
                    event.target.className += ' candidate-visited';
                };

                event.target.href = link;
            },
            getJobRequestCount: function(Candidate){
                if(Candidate.job_requests.length > 99){
                    return '99+';
                }else{
                    return Candidate.job_requests.length;
                };
            },
            getFlagStyle: function(flag){
                return {
                    backgroundColor: flag ? flag.color : 'transparent' 
                };
            }
        }
    }
</script>