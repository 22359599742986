/**
 * @created by Plamena Gancheva on 18/10/2024
 * @last-updated by Zheko Hristov on 28/10/2024
 *
 * Render pdf scripts
 **/


// Import the library
import * as pdfjsLib from "pdfjs-dist";
// Import worker
// import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker?url";

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'
// Import whatever modules you need
import { PDFSinglePageViewer, EventBus, PDFLinkService, PDFFindController, PDFScriptingManager } from "pdfjs-dist/web/pdf_viewer";
// Bind the worker for later use 
// window.pdfJSWorkerURL = pdfJSWorkerURL;

document.addEventListener('turbolinks:load', function (event) {
    const viewer = document.getElementById('pdf-viewer');
    // let url = viewer.dataset['url'];
    // console.log(url);

    if (viewer === null) {
      return;
    }

    $('#loading-background').css({ 'display': 'block' });
    $('.js-no-preview-available').removeClass('showed');

    const url = viewer.dataset.url;
    // var thePdf = null;
    let pdfDoc = null;
    let totalPagesCount = 0;
    let scaleValue = 1.5;
    const loadingTask = pdfjsLib.getDocument({ url: url, withCredentials: true });
    let renderedPagesCount = 0;

    loadingTask.promise.then(function (pdf) {
        pdfDoc = pdf;
        // $('#loading-background').css({ 'display': 'none' });
        // thePdf = pdf;
        let page;
        totalPagesCount = pdfDoc.numPages;
        for (page = 1; page <= totalPagesCount; page++) {
            const canvas = document.createElement("canvas");    
            canvas.className = 'pdf-page-canvas';         
            viewer.appendChild(canvas);            
            // renderPage(pdf, page, canvas);
        }
        displayPages(totalPagesCount);
    }, function (reason) {
        // PDF loading error
        console.error(reason);
        $('.controls__container--zoom span').addClass('disabled');
        $('#loading-background').css({ 'display': 'none' });
        $('.js-no-preview-available').addClass('showed')
        // To do - show error - Preview rendering failed and show container no preview available?
    });
    
    function renderPage(pdf, pageNumber, canvas, totalPagesCount) {
        pdf.getPage(pageNumber).then(function (page) {
            let viewport = page.getViewport({ scale: scaleValue });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
                canvasContext: canvas.getContext('2d'),
                viewport: viewport
            };

            var renderTask = page.render(renderContext);

            renderTask.promise.then(function () {
                renderedPagesCount++;
                if (renderedPagesCount === totalPagesCount) {
                    renderedPagesCount = 0;
                    $('#loading-background').css({ 'display': 'none' });
                    setTimeout(() => {
                        $('.controls__container--zoom span').removeClass('disabled');
                    }, 100);
                }
            });
        });
    }

    function displayPages(totalPagesCount) {
        $('.pdf-page-canvas').each(function (index, element) {
            const pageNum = index + 1;
            renderPage(pdfDoc, pageNum, element, totalPagesCount);
        });
    }

    $('.js-pdf-zoom-out').on('click', function () {
        if (scaleValue <= 0.5) {
            return;
        }
        $('.controls__container--zoom span').addClass('disabled');
        $('#loading-background').css({ 'display': 'block' });
        scaleValue -= 0.5;
        // show loader and disable buttons first fix
        setTimeout(function () {
            displayPages(totalPagesCount);
        }, 0);
        
    });

    $('.js-pdf-zoom-in').on('click', function () {
        $('.controls__container--zoom span').addClass('disabled');
        $('#loading-background').css({ 'display': 'block' });
        scaleValue += 0.5;
        // show loader and disable buttons first fix
        setTimeout(function () {
            displayPages(totalPagesCount);
        }, 0);
    });
});